import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import "./Layout.css"
import "./Prism.css"
import { ThemeProvider } from "./ThemeContext"
import ThemeToggle from "./ThemeToggle"

const Layout = ({ children, primaryHeader = false }) => (
  <ThemeProvider>
    <Container>
      <Header>
        {primaryHeader ? (
          <PrimaryHeader>
            joelschneider<small>(.com)</small>
          </PrimaryHeader>
        ) : (
          <h3>
            <Link to="/">
              joelschneider<small>(.com)</small>
            </Link>
          </h3>
        )}
        <ThemeToggle />
      </Header>
      <Main>{children}</Main>
      <Footer>
        <div>
          <Link to="/categories">Categories</Link>
          <Link to="/projects">Projects</Link>
        </div>
        <div>
          <a href="https://github.com/jmschneider/">Github</a>
        </div>
      </Footer>
    </Container>
  </ThemeProvider>
)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  margin: 0 auto;
  max-width: 48rem;
  padding: 0 1em;
`

const Header = styled.header`
  padding: 2rem 0 1rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  h1,
  h3 {
    margin: 0;
  }

  a {
    text-decoration: none;
  }
`

const PrimaryHeader = styled.h1`
  font-size: 1.85em;
`

const Main = styled.main`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3em;
  padding: 0.66em 0;

  a ~ a {
    margin-left: 0.5em;
  }
`

export default Layout
